import React, { ReactElement, ReactNode, useMemo } from 'react'

import { DiscountCardsResponse } from '@api/discountCards'
import Legend from '@components/SeatSelection/Legend'
import SeatComponent from '@components/SeatSelection/Seat'
import bem from '@lib/bem'

import '@components/SeatSelection/Deck/index.scss'

interface DeckProps {
  seats: Seat.Entry[]
  priceCategories: Record<number, number>
  discountCategories: DiscountCard.Item[]
  onClick: (seat: Seat.Entry) => void
  selected: Seat.Entry[]
  amenities?: ReactNode
  passengerDiscountEnabled: boolean
  passengerCard: string | null
  setPassengerCard: (value: string | null) => void
  discounts?: DiscountCardsResponse | null
}

const LAST_ROW = 5
const CENTER_ROW = 2

const updateRow = (seats: Seat.Entry[], i: number): void => {
  const currentSeat = seats[i]
  const prevSeat = seats[i - 1]
  const sub = currentSeat.coordinates.y - 1

  currentSeat.coordinates.y = sub
  if (prevSeat?.coordinates.y === sub) prevSeat.coordinates.y = sub - 1
}

const updateSeats = (seats: Seat.Entry[]): Seat.Entry[] =>
  seats.reduce<Seat.Entry[]>((acc, seat) => {
    if (acc.find(s => s.coordinates.x === seat.coordinates.x)) return acc

    const row = seats
      .filter(s => s.coordinates.x === seat.coordinates.x)
      .sort((a, b) => a.coordinates.y - b.coordinates.y)

    const last = row.findIndex(item => item.coordinates.y === LAST_ROW)
    const center = row.findIndex(item => item.coordinates.y === CENTER_ROW)

    last >= 0 && updateRow(row, last)
    center >= 0 && updateRow(row, center)

    return [...acc, ...row.flat()]
  }, [])

const Deck = (props: DeckProps): ReactElement => {
  const {
    seats,
    priceCategories,
    passengerCard,
    setPassengerCard,
    passengerDiscountEnabled,
    onClick,
    selected,
    amenities,
    discountCategories,
  } = props
  const hasPaidSeats = seats.some(seat => !!seat.price)

  const isLimitedDiscountApplied = useMemo(
    () => discountCategories.some(({ name }) => name === passengerCard),
    [discountCategories, passengerCard],
  )

  const isLimitationFulfilled = (seat: Seat.Entry): boolean => {
    if (!isLimitedDiscountApplied) return !seat.limitations?.length

    return !!seat.limitations?.some(item => passengerCard === item.name)
  }

  return (
    <>
      <Legend
        hasSelectedSeats={selected.length > 0}
        amenities={amenities}
        hasPaidSeats={hasPaidSeats}
        priceCategories={priceCategories}
        discountCategories={discountCategories}
        passengerCard={passengerCard}
        setPassengerCard={setPassengerCard}
        passengerDiscountEnabled={passengerDiscountEnabled}
        discounts={props.discounts}
      />
      <div className="seat-selection__deck-wrapper column cell-12">
        <div className={bem('seat-selection', 'deck')}>
          <div className={bem('seat-selection', 'lights', { left: true })} />
          <div className={bem('seat-selection', 'lights', { right: true })} />
          <SeatComponent coordinates={{ x: 0, y: 0 }} disabled isDriverSeat />
          {updateSeats(seats).map(seat => (
            <SeatComponent
              key={seat.id}
              price={seat.price}
              priceCategory={priceCategories[seat.price ?? 0]}
              discountCategory={seat.limitations}
              discountIndex={
                seat.limitations && discountCategories.findIndex(({ name }) => seat.limitations?.[0]?.name === name) + 1
              }
              coordinates={seat.coordinates}
              disabled={!seat.vacant || !isLimitationFulfilled(seat)}
              label={seat.code}
              onSelect={() => {
                onClick(seat)
              }}
              selected={selected.some(item => item.code === seat.code)}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default Deck
