import React, { useMemo, useState } from 'react'

import SeatsLoader from '@components/JourneyList/SeatsLoader'
import SeatSelection from '@components/SeatSelection'
import { SeatsList } from '@hooks/useSeatsController'
import { useSeatsSelection } from '@hooks/useSeatsSelection'
import seatSelectionUtils from '@lib/seatSelection'
import { useSeatsLoader } from '@loaders/seats'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

export interface SeatSelectionData {
  seats: SeatsList
  passengerCard?: DiscountCard.Item | null
}

interface Props {
  onClose: () => void
  connection: Connection
  fareClassFilter?: string | null
  initialFareClass?: string | null
  onSubmit: (data: SeatSelectionData) => void
  outboundSeatsCount?: number
  pax: number
}

const SeatsModal = (props: Props) => {
  const { onClose, connection, fareClassFilter, initialFareClass, onSubmit, outboundSeatsCount, pax } = props
  const [params] = useParams()
  const [{ paxDiscountCodes }] = useSettings()
  const isLimitedDiscountFlow = paxDiscountCodes.enabled && paxDiscountCodes.displayOn === 'search_results'
  const layout = useSeatsLoader({
    params: { ...seatSelectionUtils.buildSeatsParams(connection, params, { limitations: isLimitedDiscountFlow }) },
  })
  const isSeatsLoaded = layout.status === 'success'

  const filteredLayout = useMemo(
    () =>
      fareClassFilter && layout.data
        ? seatSelectionUtils.filterLayoutByFareClass(layout.data, fareClassFilter)
        : layout.data,
    [fareClassFilter, layout.data],
  )
  const { selectedSeats, toggleSeat, reset: resetSelected } = useSeatsSelection()
  const [segment, setSegment] = useState<number>(0)

  const selectedSegmentSeats = useMemo(() => selectedSeats[segment] ?? [], [segment, selectedSeats])

  return (
    <>
      {!isSeatsLoaded && (
        <SeatsLoader loading={layout.isLoading} error={layout.error?.code} refetch={layout.refetch} onClose={onClose} />
      )}
      {isSeatsLoaded && layout.data && (
        <SeatSelection
          opened
          layout={filteredLayout}
          selected={selectedSegmentSeats}
          onNavigateBack={() => setSegment(prev => prev - 1)}
          reservedSeatsCount={params.pax}
          outboundSeatsCount={outboundSeatsCount}
          segmentIndex={segment}
          onSelect={seat => toggleSeat(seat, segment)}
          onSubmit={passengerCard => onSubmit({ seats: selectedSeats, passengerCard })}
          onClose={onClose}
          connection={connection}
          selectedFareClassCode={initialFareClass}
          showHeader={layout.data.length >= 2}
          onClick={resetSelected}
          isFullPrice
          requiredSeats={isLimitedDiscountFlow ? pax : undefined}
          discountDropdown={isLimitedDiscountFlow && pax === 1}
        />
      )}
    </>
  )
}

export default SeatsModal
