import { useGAParams } from '@hooks/useGaParams'
import useSearchParams from '@hooks/useSearchParams'
import { SeatsList } from '@hooks/useSeatsController'
import amendmentUtils from '@lib/amendment'
import amplitude from '@lib/analytics/amplitude'
import date from '@lib/date'
import fare from '@lib/fare'
import passengersUtils from '@lib/passengers'
import seatUtils from '@lib/seatSelection'
import url from '@lib/url'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

interface TripData {
  outbound: {
    connection: Connection
    fareClassCode: string
    seats: SeatsList
  }
  inbound?: {
    connection: Connection
    fareClassCode: string
    seats: SeatsList
  } | null
  passengers: Passenger.Param[] | null
}

const useCheckoutUrl = (): ((data: TripData) => string) => {
  const [params] = useParams()
  const [queryParams] = useSearchParams()
  const { bookingId } = params
  const [{ customDomain, checkout, passengerTypesList, quickReservation, ancillaries, fareClasses }] = useSettings()
  const gaParams = useGAParams()

  const getFareClassPrice = (fareClass: string, connection: Connection): number | undefined =>
    fareClass === connection.cheapestFareClassCode
      ? connection.cheapestTotalAdultPrice.fractional
      : fare.getFareByCode(fareClass, connection)?.price.fractional

  const calculateTripPrice = ({ outbound, inbound }: TripData): number => {
    const outboundPrice = getFareClassPrice(outbound.fareClassCode, outbound.connection)
    const inboundPrice = inbound ? getFareClassPrice(inbound.fareClassCode, inbound.connection) : null

    return (outboundPrice ?? /* istanbul ignore next */ 0) + (inboundPrice ?? 0)
  }

  const calculateTotalPrice = (data: TripData): number => {
    const { outbound, inbound } = data
    const outboundSeats = seatUtils.flatten(outbound.seats)
    const inboundSeats = seatUtils.flatten(inbound?.seats)
    const seatsPrice = seatUtils.sumPrice(outboundSeats) + seatUtils.sumPrice(inboundSeats)
    const multiplier = outboundSeats.length || 1

    return seatsPrice + calculateTripPrice(data) * multiplier
  }

  const buildRedirectUrl = (data: TripData): string => {
    const { outbound, inbound } = data
    const { passengerTypes } = outbound.connection.marketingCarrier
    const defaultPassengerType = passengersUtils.buildDefaultType(passengerTypes)
    const outboundSeats = outbound.seats[0] ?? []

    const pax = passengerTypesList.enabled ? passengersUtils.getPaxCount(data.passengers) : params.pax
    const isDiscountedSeats = Object.values(outbound.seats).some(item => item.some(el => !!el.limitations?.length))
    const isPassengerData = passengerTypesList.enabled || params.bookingId || isDiscountedSeats

    const paxWithSeatDiscounts = outboundSeats.map((seat, index) => ({
      ...defaultPassengerType,
      cards: seat.limitations?.map(({ name, cardType }) => ({ name, index, type: cardType })),
    }))
    const passengers = passengersUtils.buildPassengerTypes(data.passengers ?? paxWithSeatDiscounts, passengerTypes)
    const expressPassengers = data.passengers ?? [{ type: 'PNOS', pax }]

    const parameters = {
      arrivalStationCode: outbound.connection.arrivalStation.code,
      arrivalTime: date.parse(outbound.connection.arrivalTime, 'UTC'),
      currency: params.currency,
      departureStationCode: outbound.connection.departureStation.code,
      departureTime: date.parse(outbound.connection.departureTime, 'UTC'),
      fareClass: outbound.fareClassCode,
      returnFareClass: quickReservation.enabled ? inbound?.fareClassCode : null,
      locale: params.locale,
      marketingCarrierCode: outbound.connection.marketingCarrier.code,
      pax: outboundSeats.length || pax,
      passengers: isPassengerData ? passengers : null,
      retailerPartnerNumber: params.retailerPartnerNumber,
      retailerBookingNumber: params.retailerBookingNumber,
      returnDepartureTime: inbound ? date.parse(inbound.connection.departureTime, 'UTC') : null,
      returnArrivalTime: inbound ? date.parse(inbound.connection.arrivalTime, 'UTC') : null,
      seats: {
        outbound: seatUtils.toUrlParams(outbound.seats),
        inbound: inbound?.seats ? seatUtils.toUrlParams(inbound.seats) : null,
      },
      deviceId: amplitude.getDeviceId(),
      cards: params.cards,
      mode: queryParams.mode,
      price: calculateTotalPrice(data),
      bookingId: params.bookingId,
      ga: gaParams,
      parentDomain: params.parentDomain,
    }

    if (fareClasses.displayOn === 'nowhere' && !ancillaries.enabled && !!bookingId) {
      amendmentUtils.setSessionStorage(bookingId, {
        ...amendmentUtils.getInitialStorage(),
        fareClass: outbound.fareClassCode,
      })

      return url.booking.payment(parameters)
    }

    if (checkout.type === 'new') return url.checkout.full(parameters, customDomain.enabled)
    if (checkout.type === 'express') {
      return url.checkout.express({ ...parameters, passengers: expressPassengers }, customDomain.enabled)
    }

    return url.checkout.old.full(parameters)
  }

  return buildRedirectUrl
}

export default useCheckoutUrl
