import React, { ReactElement, ReactNode, useMemo } from 'react'

import { DiscountCardsResponse } from '@api/discountCards'
import Money from '@components/Money'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import seat from '@lib/seatSelection'
import utils from '@lib/utils'
import { useParams } from '@stores/params'
import { Dropdown, Icon } from '@ui'
import { DropdownItem } from '@ui/Dropdown'

import '@components/SeatSelection/Legend/index.scss'

interface LegendProps {
  amenities?: ReactNode
  hasPaidSeats: boolean
  priceCategories: Record<number, number>
  discountCategories: DiscountCard.Item[]
  passengerCard: string | null
  setPassengerCard: (value: string | null) => void
  hasSelectedSeats: boolean
  passengerDiscountEnabled: boolean
  discounts?: DiscountCardsResponse | null
}

const DISCOUNT_SIGN = '%'

const Legend = ({
  amenities,
  hasPaidSeats,
  priceCategories,
  discountCategories,
  passengerCard,
  setPassengerCard,
  hasSelectedSeats,
  passengerDiscountEnabled,
  discounts,
}: LegendProps): ReactElement => {
  const [{ currency }] = useParams()
  const { t } = useTranslation()

  const availableCards = useMemo(
    () =>
      discounts?.filter(c => c.context === 'pax' && c.cardType === 'discount_card') ??
      /* istanbul ignore next: handle array.find */ [],
    [discounts],
  )
  const cardOptions = useMemo(() => {
    return availableCards.map<DropdownItem<string>>(card => ({
      value: card.name,
      label: card.marketingName,
    }))
  }, [availableCards])

  return (
    <div className="seat-selection__legend column gap-lg-6 row-lg">
      {passengerDiscountEnabled && (
        <div className="seat-selection__card-dropdown cell">
          <Dropdown
            items={cardOptions}
            label={t('checkout.passengerDetails.discount')}
            value={passengerCard}
            onChange={setPassengerCard}
            onReset={() => setPassengerCard(null)}
            disabled={hasSelectedSeats}
            resettable
          />
        </div>
      )}
      <div className="seat-selection__legend-amenities cell basis-auto">
        <div className="row row gap-2">{amenities}</div>
      </div>
      <div className="seat-selection__legend-info gap-3 wrap row">
        {discountCategories.map((category, index) => (
          <div key={category.name} className="row items-center">
            <div className={bem('seat-selection', 'legend-seat', { discounted: true })}>
              <span>{DISCOUNT_SIGN}</span>
              <div className="seat-selection__legend-discount-chip">{index + 1}</div>
            </div>
            <span>{category.marketingName}</span>
          </div>
        ))}
        {hasPaidSeats &&
          Object.entries(priceCategories).map(([price, count]) => (
            <div key={price} className="row items-center">
              <div className={bem('seat-selection', 'legend-seat', { paid: true })}>
                <span className="row items-center mb-1 mb-lg-2">
                  {utils.common.times(count, index => (
                    <Icon
                      className={bem('seat-selection', 'legend-seat-sign')}
                      key={index}
                      name={seat.getCurrencyIcon(currency)}
                      size="small"
                    />
                  ))}
                </span>
              </div>
              <span>
                +<Money fractional={Number(price)} currency={currency} />
              </span>
            </div>
          ))}
        <div className="row items-center">
          <div className={bem('seat-selection', 'legend-seat')} />
          <span>{t(hasPaidSeats ? 'seats.free' : 'seats.available')}</span>
        </div>
        <div className="row items-center">
          <div className={bem('seat-selection', 'legend-seat', { disabled: true })}>
            <Icon name="cross" size="medium" />
          </div>
          <span>{t('seats.not_available')}</span>
        </div>
        <div className="row items-center">
          <div className={bem('seat-selection', 'legend-seat', { selected: true })}>
            <Icon name="checkout" size="medium" />
          </div>
          <span>{t('seats.your_seat')}</span>
        </div>
      </div>
    </div>
  )
}

export default Legend
