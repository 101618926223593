import React, { ReactElement, useMemo } from 'react'

import Counter from '@components/Counter'
import config from '@config'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import passengersUtils from '@lib/passengers'
import utils from '@lib/utils'
import { useSettings } from '@queries/settings'

import '@components/PassengersList/index.scss'

interface PassengersListProps {
  value: Passenger.Param[]
  availableTypes?: Passenger.Type[] | null
  onChange: (value: Passenger.Param[]) => void
  getMaxCount?: ((totalCount: number) => number) | null
  showDiscounts?: boolean
}

const PassengersList = ({
  value,
  onChange,
  availableTypes,
  getMaxCount,
  showDiscounts,
}: PassengersListProps): ReactElement => {
  const [{ paxDiscountCodes }] = useSettings()
  const { t } = useTranslation()
  const defaultTypes = useMemo(
    () => [
      {
        id: 'passengers',
        description: '',
        code: 'passengers',
        name: t('searchBar.passengers.types.passengers'),
      },
    ],
    [t],
  )
  const types = useMemo(() => availableTypes ?? (defaultTypes as Passenger.Type[]), [availableTypes, defaultTypes])

  const typesMap = useMemo(() => passengersUtils.buildTypesMap(types), [types])

  const passengerMaxAge = useMemo(() => {
    const passengerAges = Object.values(typesMap).map(({ maxAge }) => maxAge ?? 0)

    return Math.max(...passengerAges)
  }, [typesMap])

  const getNextMinAge = (age: number, fallback: number): number | undefined => {
    const types = Object.values(typesMap).filter(({ minAge }) => minAge !== age)

    return utils.array.min(types, ({ minAge }) => minAge)?.minAge ?? fallback
  }

  const getPassengerDescription = ({ minAge, maxAge, description }: Passenger.Type): string | null => {
    if (description?.length > 0) return description
    if (minAge == null && maxAge == null) return null
    if (minAge === 0) return t('passengers.under', { maxAge: getNextMinAge(minAge, maxAge) })

    return maxAge === passengerMaxAge ? t('passengers.over', { minAge }) : t('passengers.ageRange', { minAge, maxAge })
  }

  const setPassengerAmount = (type: string, pax: number, cards?: DiscountCode.Card[]): void => {
    const updatedOptions = [...value]
    const updatedIndex = updatedOptions.findIndex(option => option.type === type)
    const isIncreased = pax > updatedOptions[updatedIndex].pax
    const paxCards = isIncreased ? cards : cards?.filter(c => c.index !== pax)

    updatedOptions.splice(updatedIndex, 1, { type, pax, cards: paxCards })

    onChange(updatedOptions)
  }

  const totalPassengersCount = value.map(v => Number(v.pax)).reduce((sum, value) => sum + value)
  const maxPassengers = getMaxCount?.(totalPassengersCount) ?? config.maxPassengers
  const hasPaxDiscount = value.some(({ cards }) => cards?.some(({ type }) => type === 'discount_card'))
  const isLimitedDiscountFlow = showDiscounts && paxDiscountCodes.displayOn === 'search_results' && hasPaxDiscount

  return (
    <div className="column gap-3 p-1">
      {value.map(({ type, pax, cards }) => (
        <Counter
          key={type}
          label={typesMap[type].name}
          description={getPassengerDescription(typesMap[type])}
          value={Number(pax)}
          max={isLimitedDiscountFlow ? 1 : maxPassengers}
          min={totalPassengersCount > 1 ? 0 : 1}
          onChange={count => {
            setPassengerAmount(type, count, cards)
          }}
        />
      ))}
      {isLimitedDiscountFlow && (
        <div className={bem('passenger-counters', 'limited-discount-message')}>
          {t('searchBar.passengers.limitedDiscount.maxPassengers')}
        </div>
      )}
    </div>
  )
}

export default PassengersList
