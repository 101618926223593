import React, { ReactElement } from 'react'
import { createPortal } from 'react-dom'
import { QueryObserverResult } from 'react-query'

import Loader from '@components/Loader'
import noSeats from '@images/extras/no-seats.png'
import { ApiError } from '@lib/api'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { Button, Icon } from '@ui'

import '@components/JourneyList/SeatsLoader/index.scss'

interface SeatsLoaderProps {
  loading: boolean
  onClose: () => void
  error?: ErrorCode | null
  refetch: () => Promise<QueryObserverResult<Seat.Data[], ApiError>>
}

const SeatsLoader = ({ loading, onClose, refetch, error }: SeatsLoaderProps): ReactElement => {
  const { t } = useTranslation()

  const handleBackdropClick = (): void => {
    !loading && onClose()
  }

  return createPortal(
    <>
      <div className={bem('journey-list', 'seats-loader-overlay')} onClick={handleBackdropClick} />
      <div className={bem('journey-list', 'seats-loader-wrapper', { loading, error })}>
        <Loader loading={loading}>
          {error && (
            <div className="column">
              <div className="journey-list__seats-loader-header row center mb-5">
                <img src={noSeats} alt="seats are not available" />
                <Icon name="cross" size="large" onClick={onClose} />
              </div>
              <div className="journey-list__seats-loader-body column items-center mb-7">
                <h3 className="mb-3">{t('seats.warning.no_seats')}</h3>
                <span>{t('seats.warning.try_again')}</span>
              </div>
              <div className="journey-list__seats-loader-footer column row-lg center gap-3">
                <Button variant="outline" color="secondary" onClick={onClose}>
                  {t('seats.choose_trip')}
                </Button>
                <Button
                  onClick={() => {
                    void refetch()
                  }}
                >
                  {t('seats.try_again')}
                </Button>
              </div>
            </div>
          )}
        </Loader>
      </div>
    </>,
    document.body,
  )
}

export default SeatsLoader
