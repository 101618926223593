import cn from 'classnames'
import { useFormikContext } from 'formik'
import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { UseQueryResult } from 'react-query'

import { DiscountCardsResponse } from '@api/discountCards'
import Loader from '@components/Loader'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import passengerUtils from '@lib/passengers'
import DiscountSelect from '@pages/Checkout/PassengerDetails/PassengerFieldset/DiscountSelect'
import { useSettings } from '@queries/settings'
import { Divider } from '@ui'

interface PassengersDiscountsProps {
  passengers: Passenger.Param[]
  discounts?: UseQueryResult<DiscountCardsResponse>
}

type DiscountsErrors = Record<DiscountCard.Type, Record<string, string | null | undefined>>

const splitDiscounts = (cards: DiscountCode.Card[], index: number): DiscountCode.Card[] =>
  cards.filter(c => c.index === index)

const getCardIndex = (passengers: Passenger.Param[], type: string, index: number): number => {
  const otherTypes = passengers.filter(p => p.type !== type)
  const cardIndex = index - otherTypes.length

  return cardIndex <= 0 ? 0 : cardIndex
}

const PassengersDiscounts = (props: PassengersDiscountsProps): ReactElement => {
  const { passengers, discounts } = props
  const { setFieldValue } = useFormikContext()
  const [errors, setErrors] = useState<DiscountsErrors>({ discount_card: { 0: null }, loyalty: { 0: null } })
  const { t } = useTranslation()
  const [{ paxDiscountCodes }] = useSettings()

  const getCards = useCallback(
    (paxType: string, cardType: DiscountCard.Type): DiscountCard.Item[] =>
      discounts?.data?.filter(c => {
        return c.context === 'pax' && c.cardType === cardType && c.supportedPassengerTypeCodes.includes(paxType)
      }) ?? /* istanbul ignore next: handle array.find */ [],
    [discounts],
  )

  const filteredPax: Passenger.Param[] = useMemo(() => {
    const filtered = passengers.filter(({ pax }) => pax > 0)

    return filtered
      .map(passenger =>
        new Array(Number(passenger.pax))
          .fill(passenger)
          .map((pax, index) => ({ ...pax, pax: 1, cards: splitDiscounts(pax.cards, index) })),
      )
      .flat()
  }, [passengers])

  const handleChange = (
    card: DiscountCode.Card | null,
    paxType: string,
    index: number,
    cardType: DiscountCard.Type,
  ): void => {
    const copiedPax = [...passengers]
    const discounts = copiedPax.find(p => p.type === paxType)?.cards
    const cardIndex = getCardIndex(filteredPax, paxType, index)
    const discountIndex =
      discounts?.findIndex(card => card.index === cardIndex && card.type === cardType) ??
      /* istanbul ignore next: handle array.find */ -1

    if (discountIndex >= 0 && card) discounts?.splice(discountIndex, 1, { ...card, index: cardIndex, type: cardType })
    else if (discountIndex >= 0 && !card) discounts?.splice(discountIndex, 1)
    else card && discounts?.push({ ...card, index: cardIndex, type: cardType })

    setFieldValue('passengers', copiedPax)
  }

  const handleError = (error: string | undefined, index: number, type: DiscountCard.Type): void => {
    setErrors({ ...errors, [type]: { ...errors[type], [index]: error } })
  }

  const isShown = (paxType: string, cardType: DiscountCard.Type): boolean => getCards(paxType, cardType).length > 0
  const isLimitedDiscountFlow =
    paxDiscountCodes.enabled &&
    paxDiscountCodes.displayOn === 'search_results' &&
    passengerUtils.getPaxCount(passengers) > 1

  return (
    <div className="passenger-dropdown__discounts">
      {isLimitedDiscountFlow && (
        <div className={cn(bem('passenger-counters', 'limited-discount-message'), 'mb-2')}>
          {t('searchBar.passengers.limitedDiscount.maxPassengers')}
        </div>
      )}
      {filteredPax.map(({ type, cards }, index) => (
        <Loader key={type + index} loading={!!discounts?.isLoading}>
          {(isShown(type, 'discount_card') || isShown(type, 'loyalty')) && (
            <div>
              <div className="passenger-dropdown__discounts-label mb-3">
                <Trans
                  i18nKey="searchBar.passengers.discountLabel"
                  values={{ index: index + 1, type: t(`searchBar.passengers.codes.${type}`) }}
                  components={{ span: <span />, b: <b /> }}
                />
              </div>
              <div className="column gap-3 wrap">
                {isShown(type, 'discount_card') && (
                  <DiscountSelect
                    label={t('checkout.passengerDetails.discount')}
                    initialValues={cards?.find(c => c.type === 'discount_card')}
                    availableCards={getCards(type, 'discount_card')}
                    onChange={card => handleChange(card, type, index, 'discount_card')}
                    setError={error => handleError(error, index, 'discount_card')}
                    error={errors.discount_card[index]}
                    fullWidth
                    disabled={isLimitedDiscountFlow}
                  />
                )}
                {isShown(type, 'loyalty') && (
                  <DiscountSelect
                    label={t('checkout.passengerDetails.loyalty')}
                    initialValues={cards?.find(c => c.type === 'loyalty')}
                    availableCards={getCards(type, 'loyalty')}
                    onChange={card => handleChange(card, type, index, 'loyalty')}
                    setError={error => handleError(error, index, 'loyalty')}
                    error={errors.loyalty[index]}
                    fullWidth
                  />
                )}
              </div>
              {index !== filteredPax.length - 1 && <Divider />}
            </div>
          )}
        </Loader>
      ))}
    </div>
  )
}

export default PassengersDiscounts
