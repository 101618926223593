import { FormikProps } from 'formik'
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { BookingStatusResponse } from '@api/booking'
import * as Reservation from '@api/reservation'
import useBookingFlow, { FinishPaymentParams } from '@hooks/useBookingFlow'
import errorImage from '@images/reservations/error.png'
import amplitude from '@lib/analytics/amplitude'
import checkoutUtils from '@lib/checkout'
import { useTranslation } from '@lib/i18n'
import { CallLoaderOptions } from '@lib/loader'
import { MutationResult } from '@lib/mutation'
import { MaxAttemptsError, PollingApiError } from '@lib/polling'
import reservationUtils from '@lib/reservation'
import { useBookingStatusPolling } from '@loaders/bookingStatusPolling'
import { useReservationCreate } from '@loaders/createReservation'
import { CheckoutFormProps } from '@pages/Checkout/Form'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useReservationSession } from '@pages/Checkout/hooks/useReservationSession'
import ReservationErrorModal from '@pages/Checkout/ReservationsErrorModal'
import Default from '@pages/Checkout/ReservationsErrorModal/Footer/Default'
import ReservationsExpiredModal from '@pages/Checkout/ReservationsExpiredModal'
import { useSettings } from '@queries/settings'
import { useCheckout } from '@stores/checkout'
import { ParamsStore, useParams } from '@stores/params'

const PAYMENT_ATTEMPTS = 2

interface SubmitReservationProps<TMutationParams> {
  form: React.FC<CheckoutFormProps>
  submitHook: (
    callOptions?: CallLoaderOptions<TMutationParams | null, Reservation.ConfirmResponse>,
  ) => MutationResult<Reservation.ConfirmResponse, any, TMutationParams>
  transformParams: (data: CheckoutFormData, params: ParamsStore) => TMutationParams
  confirmationOnly?: boolean
}

const SubmitReservation = <T,>(props: SubmitReservationProps<T>): ReactElement | null => {
  const { form: CheckoutForm, submitHook, transformParams } = props
  const { t } = useTranslation()
  const [params] = useParams()
  const [{ quickReservation, reselling }] = useSettings()
  const [{ inbound, outbound }] = useCheckout()
  const navigate = useNavigate()

  console.log('render reservation')

  const form = useRef<FormikProps<CheckoutFormData>>(null)
  const [stopReservationTimer, setStopReservationTimer] = useState<boolean>(false)
  const [paymentAttempts, setPaymentAttempts] = useState<number>(0)
  const [reservationBookingError, setReservationBookingError] = useState(false)
  const { pathname, search } = useLocation()

  const handleStatusError = useCallback((error: PollingApiError): void => {
    if (!(error instanceof MaxAttemptsError)) {
      setReservationBookingError(true)
      setStopReservationTimer(true)
    }
  }, [])
  const finishPayment = useCallback(
    (path: string) => {
      if (form.current && outbound) {
        amplitude.checkout.purchaseTrip({
          connections: { outbound, inbound },
          formData: form.current.values,
          currency: params.currency,
          reselling: reselling.enabled,
        })
      }
      sessionStorage.clear()
      navigate(checkoutUtils.buildConfirmationPageUrl(params, path))
    },
    [inbound, navigate, outbound, params, reselling.enabled],
  )
  const bookingStatusProps = useMemo(
    () => ({
      onSuccess: ({ redirectPath }: BookingStatusResponse) => {
        finishPayment(redirectPath)
      },
      onError: handleStatusError,
    }),
    [finishPayment, handleStatusError],
  )

  const { mutate: startPolling, ...statusPolling } = useBookingStatusPolling(bookingStatusProps)
  const onSuccess = useCallback(
    ({ action, redirectPath, bookingFormId }: FinishPaymentParams) => {
      if (action === 'verify_reservation' && bookingFormId != null) startPolling(bookingFormId)
      if (action === 'redirect' && redirectPath) finishPayment(redirectPath)
    },
    [finishPayment, startPolling],
  )

  const bookingHandlers = useBookingFlow({ form, onSuccess })

  const reservationConfirm = submitHook({ onSuccess: bookingHandlers.confirmPayment })
  const quickReservationCreate = useReservationCreate()
  const isLoading = quickReservationCreate.isLoading || reservationConfirm.isLoading || statusPolling.isLoading
  const error = quickReservationCreate.error ?? reservationConfirm.error ?? statusPolling.error

  useEffect(() => {
    if (paymentAttempts === PAYMENT_ATTEMPTS && reservationConfirm.errorCode) {
      setStopReservationTimer(true)
      setReservationBookingError(true)
    }
  }, [paymentAttempts, error, reservationConfirm.errorCode])

  const confirmReservation = (data: CheckoutFormData): void => {
    setPaymentAttempts(paymentAttempts + 1)
    const confirmParams = transformParams(data, params)
    reservationConfirm.mutate(confirmParams)
  }
  const submitQuickReservation = async (data: CheckoutFormData): Promise<void> => {
    const buildParams = { data, params, inbound, outbound: outbound as Connection }
    const createParams = reservationUtils.buildQuickCreateParams(buildParams)
    const reservation = await quickReservationCreate.mutateAsync(createParams)

    if (bookingHandlers.quickReservation(reservation).shouldConfirm) {
      reservationUtils.setCheckoutPath(String(reservation.id), `${pathname}${search}`)
      confirmReservation({ ...data, reservationData: reservationUtils.adjustTimeZones(reservation) })
    }
  }

  const submit = async (formData: CheckoutFormData): Promise<void> => {
    const data = await bookingHandlers.submitForm(formData)
    quickReservation.enabled && !props.confirmationOnly ? await submitQuickReservation(data) : confirmReservation(data)
  }

  const { initializeReservation, createError, expiredError, timer } = useReservationSession(form)
  useEffect(() => {
    if (!quickReservation.enabled && !props.confirmationOnly) {
      initializeReservation()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outbound])
  const checkPrice = useCallback(
    ({ reservationData }: CheckoutFormData): boolean =>
      !!reservationData &&
      checkoutUtils.totalPriceWithFees(reservationData.price, reservationData.fees).fractional <= 0,
    [],
  )

  return (
    <>
      <CheckoutForm
        finishPayment={onSuccess}
        submit={submit}
        validate={bookingHandlers.validate}
        innerRef={form}
        isLoading={isLoading}
        error={paymentAttempts < PAYMENT_ATTEMPTS && !reservationBookingError ? error : null}
        confirmationOnly={props.confirmationOnly}
        timer={timer && { ...timer, isStopped: stopReservationTimer }}
        isRefund={checkPrice}
      />
      <ReservationErrorModal
        isOpened={reservationBookingError}
        title={t('checkout.reservationModal.errorHeader')}
        body={t('checkout.reservationBookingError')}
        footer={<Default />}
        iconPath={errorImage}
      />
      <ReservationErrorModal
        isOpened={!!createError}
        body={t('checkout.reservationModal.createErrorBody')}
        title={t('checkout.reservationModal.errorHeader')}
        footer={<Default />}
        iconPath={errorImage}
      />
      <ReservationsExpiredModal opened={expiredError != null} period={expiredError?.period} />
    </>
  )
}

export default SubmitReservation
