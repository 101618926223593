import { useFormikContext } from 'formik'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import { DiscountCardsResponse } from '@api/discountCards'
import { useTranslation } from '@lib/i18n'
import passengerUtils from '@lib/passengers'
import utils from '@lib/utils'
import DiscountsSkeleton from '@pages/Checkout/Discounts/Skeleton'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import DiscountSelect from '@pages/Checkout/PassengerDetails/PassengerFieldset/DiscountSelect'
import { useSettings } from '@queries/settings'
import { PassengerData, useCheckout } from '@stores/checkout'
import { useParams } from '@stores/params'
import { Skeleton } from '@ui'

interface PassengerDiscountsProps {
  name: string
  cards?: DiscountCardsResponse | null
  isLoading: boolean
}

const findCard = (card: DiscountCode.Card[] | undefined, type: DiscountCard.Type): DiscountCode.Card | undefined =>
  card?.find(card => card.type === type)
const buildPassengers = (passengers: PassengerData[], passengerTypes: Passenger.Type[]): Passenger.Param[] => {
  const list = passengerUtils.reducePassengers(passengers)

  return passengerUtils.buildPassengerTypes(list, passengerTypes)
}

const PassengerDiscounts = ({ name, cards, isLoading }: PassengerDiscountsProps): ReactElement => {
  const { t } = useTranslation()
  const [, setParams] = useParams()
  const [{ fareClasses, paxDiscountCodes }] = useSettings()
  const { values, getFieldMeta, setFieldValue, setFieldError } = useFormikContext<CheckoutFormData>()
  const { value: passenger } = getFieldMeta<PassengerData>(name)
  const { error } = getFieldMeta<PassengerData>(`${name}.cards`)
  const [{ outbound }] = useCheckout()
  const index = Number(name.at(-1))

  const [loyaltyCard, setLoyaltyCard] = useState<DiscountCode.Card | null>(
    () => findCard(passenger.cards, 'loyalty') ?? null,
  )
  const [discountCard, setDiscountCard] = useState<DiscountCode.Card | null>(
    () => findCard(passenger.cards, 'discount_card') ?? null,
  )

  const isFareClassesEnabled = fareClasses.displayOn === 'checkout' || fareClasses.displayOn === 'everywhere'
  const isPaxDiscountEnabled =
    paxDiscountCodes.enabled &&
    (paxDiscountCodes.displayOn === 'checkout' || paxDiscountCodes.displayOn === 'everywhere')
  const passengerTypes = outbound?.marketingCarrier.passengerTypes ?? /* istanbul ignore next */ []

  useEffect(() => {
    setFieldValue(
      `${name}.cards`,
      utils.array.compact([discountCard, loyaltyCard]).map(card => ({ ...card, index })),
    )
  }, [discountCard, index, loyaltyCard, name, setFieldValue])

  const passengerCards = useMemo(
    () => cards?.filter(c => c.context === 'pax' && c.supportedPassengerTypeCodes.includes(passenger.type)),
    [cards, passenger.type],
  )
  const discountCards = useMemo(() => passengerCards?.filter(c => c.cardType === 'discount_card'), [passengerCards])
  const loyaltyCards = useMemo(() => passengerCards?.filter(c => c.cardType === 'loyalty'), [passengerCards])
  const setError = (error: string | undefined): void => {
    setFieldError(`${name}.cards`, error)
  }
  const setParamsPassengers = (discounts: DiscountCode.Card[]): void => {
    const copiedPassengers = [...values.passengers]
    copiedPassengers[index].cards = discounts.map(card => ({ ...card, index }))

    setParams({ passengers: buildPassengers(copiedPassengers, passengerTypes) })
  }

  const handleDiscountChange = (discount: DiscountCode.Card | null): void => {
    setDiscountCard(discount)
    isFareClassesEnabled && setParamsPassengers(utils.array.compact([discount, loyaltyCard]))
    setFieldValue('vacancies', [])
  }
  const handleLoyaltyCardChange = (discount: DiscountCode.Card | null): void => {
    setLoyaltyCard(discount)
    isFareClassesEnabled && setParamsPassengers(utils.array.compact([discountCard, discount]))
    setFieldValue('vacancies', [])
  }

  return (
    <Skeleton.List Skeleton={DiscountsSkeleton} loading={isLoading} amount={1}>
      <div className="cell">
        <div className="row gap-3 wrap column-sm">
          {discountCards && discountCards.length > 0 && (
            <DiscountSelect
              availableCards={discountCards}
              label={t('checkout.passengerDetails.discount')}
              onChange={handleDiscountChange}
              initialValues={findCard(passenger.cards, 'discount_card')}
              error={error}
              disabled={!isPaxDiscountEnabled}
              setError={setError}
            />
          )}
          {loyaltyCards && loyaltyCards.length > 0 && (
            <DiscountSelect
              availableCards={loyaltyCards}
              label={t('checkout.passengerDetails.loyalty')}
              onChange={handleLoyaltyCardChange}
              initialValues={findCard(passenger.cards, 'loyalty')}
              error={error}
              setError={setError}
            />
          )}
        </div>
      </div>
    </Skeleton.List>
  )
}

export default PassengerDiscounts
