import { isToday } from 'date-fns'

import { SettingsResponse } from '@api/settings'
import config from '@config'
import currencyUtils from '@lib/currency'
import dateUtils from '@lib/date'
import fareClassUtils from '@lib/fareClass'
import localeUtils from '@lib/locale'
import utils from '@lib/utils'
import { ParamsStore } from '@stores/params'

type Type = 'departure' | 'arrival'

interface FlatLocation {
  arrivalArea?: string | null
  arrivalCity?: string | null
  arrivalStation?: string | null
  departureArea?: string | null
  departureCity?: string | null
  departureStation?: string | null
}

interface normaliseParamsInterface {
  retailerPartnerNumber: RetailerNumber | null
  params: Record<string, any>
  settings: SettingsResponse
}

const flatLocation = (location: Location.Item, type: Type): FlatLocation => {
  if (location.type === 'station') return { [`${type}Station`]: location.code }
  if (location.type === 'area') return { [`${type}Area`]: location.code }

  return { [`${type}City`]: location.code }
}

const unflatLocation = (location: FlatLocation): Location.Item | null => {
  if (location.arrivalStation) return { code: location.arrivalStation, type: 'station' }
  if (location.departureStation) return { code: location.departureStation, type: 'station' }
  if (location.arrivalArea) return { code: location.arrivalArea, type: 'area' }
  if (location.departureArea) return { code: location.departureArea, type: 'area' }
  if (location.arrivalCity) return { code: location.arrivalCity, type: 'city' }
  if (location.departureCity) return { code: location.departureCity, type: 'city' }

  return null
}

const getDefaultDepartureTime = (timeFieldEnabled: boolean, departureDate: Date): string => {
  const currentDate = dateUtils.today()
  const today = isToday(departureDate)

  const timeFieldValue = today ? dateUtils.ceilHour(currentDate) : config.defaultDepartureTime

  return timeFieldEnabled ? timeFieldValue : dateUtils.startOfDay()
}

export type PersistentParams = Pick<
  ParamsStore,
  'marketingCarrierCode' | 'retailerPartnerNumber' | 'retailerBookingNumber' | 'locale' | 'mode' | 'parentDomain'
>
type PersistentParamsProps = PersistentParams | ParamsStore

const getPersistentUrlParams = (params: PersistentParamsProps): PersistentParams =>
  utils.object.compact({
    marketingCarrierCode: params.marketingCarrierCode,
    retailerPartnerNumber: params.retailerPartnerNumber,
    retailerBookingNumber: params.retailerBookingNumber,
    locale: params.locale,
    mode: params.mode === 'page' ? null : params.mode,
  })

const parseDate = (value?: string): string | null => {
  if (value == null) return null

  return dateUtils.formatDate(dateUtils.parse(value))
}

const parseInteger = (value?: string): number | null => {
  if (value == null) return null

  const parsed = parseInt(value)

  return isNaN(parsed) ? null : parsed
}

const parseNumber = (value?: string): number | null => {
  if (value == null) return null

  const parsed = parseFloat(value)

  return parsed == null || isNaN(parsed) ? /* istanbul ignore next */ null : parsed
}

const parseSeats = (value?: Record<ConnectionType, string[]>): Seat.TripParams | null => {
  if (!value) return null

  const entries = Object.entries(value).map(([key, value]) => [key, { ...value }])

  return Object.fromEntries(entries)
}

const parsePassengers = (passengers: Passenger.Param[]): Passenger.Param[] | undefined =>
  passengers?.map(pax => ({
    ...pax,
    pax: Number(pax.pax),
    cards: pax.cards?.map(card => ({ ...card, index: Number(card.index) })) ?? [],
  }))

const normaliseParams = ({ retailerPartnerNumber, params, settings }: normaliseParamsInterface) => {
  const arrivalData = {
    arrivalStation: params.arrivalStation,
    arrivalArea: params.arrivalArea,
    arrivalCity: params.arrivalCity,
  }

  const departureData = {
    departureStation: params.departureStation,
    departureArea: params.departureArea,
    departureCity: params.departureCity,
  }

  const locale = localeUtils.getPreferred(settings.locale.supported) ?? settings.locale.default
  const fixedFareClass = retailerPartnerNumber && fareClassUtils.getFixedClass(retailerPartnerNumber, params)

  return {
    departureLocation: unflatLocation(departureData),
    arrivalLocation: unflatLocation(arrivalData),
    departureDate: parseDate(params.departureDate) ?? dateUtils.formatDate(dateUtils.today()),
    departureTime: params.departureTime,
    arrivalDate: parseDate(params.arrivalDate),
    arrivalTime: params.arrivalTime,
    returnDepartureDate: parseDate(params.returnDepartureDate),
    returnDepartureTime: params.returnDepartureTime,
    returnArrivalDate: parseDate(params.returnArrivalDate),
    returnArrivalTime: params.returnArrivalTime,
    fareClass: fixedFareClass ?? params.fareClass,
    returnFareClass: params.returnFareClass,
    marketingCarrierCode: params.marketingCarrierCode,
    pax: parseInteger(params.pax) ?? config.fallback.pax,
    retailerPartnerNumber: retailerPartnerNumber ?? /* istanbul ignore next */ 0,
    returnDate: parseDate(params.returnDate),
    locale,
    currency: utils.array.containsOrDefault(
      params.currency,
      settings.currency.supported,
      currencyUtils.getBestCurrency(settings.currency.supported, settings.currency.default),
    ),
    sorting: utils.array.containsOrDefault(params.sorting, settings.sorting.supported, settings.sorting.default),
    seats: parseSeats(params.seats),
    mode: utils.array.containsOrDefault(params.mode, ['page', 'embed'], 'page'),
    express: Boolean(params.express),
    passengers: parsePassengers(params.passengers),
    cards: params.cards,
    passengersCards: parsePassengersCards(params.passengers),
    price: parseNumber(params.price),
    retailerBookingNumber: params.retailerBookingNumber,
    bookingId: params.bookingId,
    departureEndTime: params.departureEndTime,
    parentDomain: params.parentDomain,
  }
}

const getUtmParams = (search: string): Record<string, string> => {
  const utmParams: Record<string, string> = {}
  const urlParams = new URLSearchParams(search)

  for (const [key, value] of urlParams.entries()) {
    if (key.startsWith('utm_')) utmParams[key] = value
  }

  return utmParams
}

const parsePassengersCards = (passengers: Passenger.Param[]): string[] => {
  const cards = passengers
    ?.map((passenger: Passenger.Param) => passenger.cards?.map(card => card.name))
    .flat()
    .filter((name): name is string => !!name)

  return Array.from(new Set(cards))
}

const paramsUtils = {
  flatLocation,
  unflatLocation,
  getDefaultDepartureTime,
  getPersistentUrlParams,
  normaliseParams,
  getUtmParams,
}

export default paramsUtils
