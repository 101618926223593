import { FormikErrors } from 'formik'
import React, { ReactElement, useMemo, useRef, useState } from 'react'
import { UseQueryResult } from 'react-query'

import { DiscountCardsResponse } from '@api/discountCards'
import PassengersModal from '@components/PassengersDropdown/PassengersModal'
import PassengersTabs from '@components/PassengersDropdown/PassengersTabs'
import useClickOutside from '@hooks/useClickOutside'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import passengersUtils from '@lib/passengers'
import Icon from '@ui/Icon'

import '@components/PassengersDropdown/index.scss'

interface PassengersDropdownProps {
  label: string
  value: Passenger.Param[]
  availableTypes?: Passenger.Type[] | null
  onChange: (value: Passenger.Param[]) => void
  getMaxCount?: ((totalCount: number) => number) | null
  errors?: FormikErrors<{ passengers: string }>
  discounts?: UseQueryResult<DiscountCardsResponse>
  showDiscounts?: boolean
}

const PassengersDropdown = (props: PassengersDropdownProps): ReactElement => {
  const { t } = useTranslation()
  const { label, availableTypes, value, errors } = props
  const isMobile = useIsMobile()
  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false)
  const closePopup = (): void => {
    setIsPopupOpened(false)
  }

  const togglePopup = (): void => {
    setIsPopupOpened(prevState => !prevState)
  }
  const typesMap = useMemo(() => availableTypes && passengersUtils.buildTypesMap(availableTypes), [availableTypes])
  const getDisplayValue = (passengers: Passenger.Param[]): string => {
    if (!typesMap) return passengers.reduce((acc, { pax }) => acc + pax, 0).toString()
    const selected = passengers.filter(passenger => passenger.pax > 0)
    const discountsAmount = selected.reduce((acc, curr) => acc + Number(curr.cards?.length ?? 0), 0)
    const discounts = t('searchBar.passengers.discountAmount', { count: discountsAmount })
    const pax = selected.map(passenger => `${passenger.pax} ${typesMap[passenger.type].name}`)

    return [...pax, discounts].filter(Boolean).join(', ')
  }

  const displayValue = getDisplayValue(value)
  const focused = displayValue?.length
  const wrapperRef = useRef<HTMLInputElement>(null)

  useClickOutside(wrapperRef, () => {
    if (!isMobile) setIsPopupOpened(false)
  })

  return (
    <div className="passenger-dropdown" ref={wrapperRef}>
      <div
        className={bem('passenger-dropdown', 'input-wrapper', { active: isPopupOpened, error: errors?.passengers })}
        onClick={togglePopup}
      >
        <div className="passenger-dropdown__icon">
          <Icon name="passenger" size="large" />
        </div>
        <div className="passenger-dropdown__value">
          <div className={bem('passenger-dropdown', 'label', { focused })}>{label}</div>
          <div className={bem('passenger-dropdown', 'description')}>{displayValue}</div>
        </div>
        {typesMap && (
          <span className="mr-1">
            <Icon name="chevron-down" size="medium" />
          </span>
        )}
      </div>
      {errors?.passengers && (
        <div className="row start passenger-dropdown__error-message">
          <Icon name="alert" size="small" />
          <span>{errors.passengers}</span>
        </div>
      )}
      {isMobile && <PassengersModal opened={isPopupOpened} onClose={closePopup} {...props} />}
      {!isMobile && isPopupOpened && (
        <div className={bem('passenger-dropdown', 'popup')}>
          <PassengersTabs {...props} />
        </div>
      )}
    </div>
  )
}

export default PassengersDropdown
