import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import Amenity from '@components/JourneyCard/Footer/Amenity'
import AlternativeDiscountModal from '@components/SeatSelection/AlternativeDiscountModal'
import Deck from '@components/SeatSelection/Deck'
import Footer from '@components/SeatSelection/Footer'
import Header from '@components/SeatSelection/Header'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import fareUtils from '@lib/fare'
import { useTranslation } from '@lib/i18n'
import seatSelectionUtils from '@lib/seatSelection'
import utils from '@lib/utils'
import { useDiscounts } from '@queries/dicounts'
import { useParams } from '@stores/params'
import { Modal, Tabs } from '@ui'

import '@components/SeatSelection/index.scss'

interface SeatSelectionProps {
  opened: boolean
  showHeader: boolean
  layout: Seat.Data[]
  connection: Connection | null
  selected: Seat.Entry[]
  onNavigateBack: () => void
  reservedSeatsCount: number
  segmentIndex: number
  onSelect: (seat: Seat.Entry) => void
  onSubmit: (passengerCard?: DiscountCard.Item | null) => void
  onClose: () => void
  onClick?: () => void
  selectedFareClassCode?: string | null
  outboundSeatsCount?: number
  isFullPrice?: boolean
  requiredSeats?: number
  discountDropdown?: boolean
}

const getInitialLevel = (layout: Seat.Data, fareClassCode: string): number => {
  return layout?.cars[0].seats.find(item => item.fareClass === fareClassCode)?.coordinates.z ?? 0
}

const isSeat = (seat: Seat.Entry): boolean => seat.code !== 'WC' && seat.code !== 'ES'

const SeatSelection = ({
  opened,
  showHeader,
  layout,
  connection,
  selected,
  reservedSeatsCount,
  segmentIndex,
  onSelect,
  onSubmit,
  onClose,
  onClick,
  onNavigateBack,
  selectedFareClassCode,
  outboundSeatsCount = 0,
  isFullPrice,
  requiredSeats,
  discountDropdown,
}: SeatSelectionProps): ReactElement => {
  const [fareClassCode, setFareClassCode] = useState<string>('')
  const [deckLevel, setDeckLevel] = useState<number>(0)
  const [{ currency, passengers }] = useParams()
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const { cars, segment } = { ...layout[segmentIndex] }
  const seats = useMemo(() => cars?.flatMap(car => car.seats) ?? /* istanbul ignore next */ [], [cars])

  useEffect(() => {
    const fareClassCode = selectedFareClassCode || seatSelectionUtils.getUniqueFares(layout[segmentIndex])[0]
    const deckLevel = getInitialLevel(layout[segmentIndex], fareClassCode)

    setFareClassCode(fareClassCode)
    setDeckLevel(deckLevel)
  }, [layout, segmentIndex, selectedFareClassCode, opened])

  useEffect(() => {
    if (fareClassCode) {
      setDeckLevel(getInitialLevel(layout[segmentIndex], fareClassCode))
    }
  }, [fareClassCode, layout, segmentIndex, opened])

  const sumPrice = useMemo(() => {
    if (selected.some(seat => !!seat.limitations?.length)) return null

    const fractional = utils.array.sum(selected, ({ price, fareClass }) => {
      const fare = isFullPrice ? connection?.fares?.find(el => el.fareClass.code === fareClass) : null
      const farePrice: number = fare?.price?.fractional ?? /* istanbul ignore next */ 0

      if (requiredSeats != null) return Number(price) + farePrice / (requiredSeats ?? 1)

      return Number(price) + farePrice
    })

    return { fractional, currency }
  }, [connection, currency, isFullPrice, requiredSeats, selected])

  const deckTabs = useMemo(
    () =>
      seatSelectionUtils.getUniqueLevel(layout[segmentIndex], fareClassCode).map(item => ({
        key: item,
        label: item === 0 ? t('seats.lower_deck') : t('seats.upper_deck'),
      })),
    [layout, segmentIndex, t, fareClassCode],
  )

  const faresTabs = useMemo(
    () =>
      seatSelectionUtils
        .getUniqueFares(layout[segmentIndex])
        .map(item => ({
          key: item,
          label: utils.string.capitalize(
            fareUtils.getFareByCode(item, connection)?.fareClass.name ?? /* istanbul ignore next */ '',
          ),
        }))
        .filter(item => item.label),
    [connection, layout, segmentIndex],
  )
  console.log(faresTabs, layout)

  const filteredSeats = useMemo(() => {
    const levels = cars?.flatMap(item => item.seats.filter(el => el.coordinates.z === deckLevel))

    return fareClassCode
      ? levels?.filter(item => item.fareClass === fareClassCode || !item.fareClass || !isSeat(item))
      : levels
  }, [cars, deckLevel, fareClassCode])

  const amenities = useMemo(() => {
    const fareFeature = fareUtils.getFareByCode(fareClassCode, connection)

    return fareFeature?.fareClass.fareFeatures.map(item => (
      <Amenity key={item.id} fareFeature={item} tooltipPosition="bottom" />
    ))
  }, [connection, fareClassCode])

  const handleChangeFareClass = (fareClassCode: string): void => {
    setFareClassCode(fareClassCode)
    onClick?.()
  }

  const handleChangeDeckLevel = (level: number): void => {
    setDeckLevel(level)
  }

  const header =
    showHeader && layout.length ? (
      <Header
        segment={segment}
        connection={connection}
        onNavigateBack={onNavigateBack}
        segmentsCount={layout.length}
        segmentIndex={segmentIndex}
      />
    ) : null

  const discounts = useDiscounts({ enabled: !!discountDropdown })
  const priceCategories = seatSelectionUtils.getPriceCategories(seats)
  const discountCategories = useMemo(() => seatSelectionUtils.getDiscountCategories(cars), [cars])

  const firstPassengerCard = passengers?.[0]?.cards?.[0]?.name ?? null
  const passengerDiscountEnabled = discountCategories.length > 0 && !!discountDropdown

  const [passengerCard, setPassengerCard] = useState<string | null>(firstPassengerCard)

  const submit = () => {
    if (!passengerDiscountEnabled) return onSubmit()
    if (!passengerCard) return onSubmit(null)

    onSubmit(utils.array.findBy(discounts.data ?? [], 'name', passengerCard))
  }

  const [altDiscountOpened, setAltDiscountOpened] = useState<boolean>(false)
  useEffect(() => {
    if (opened) {
      const isOpened = firstPassengerCard != null && seatSelectionUtils.isLimitedSeatsTaken(seats, firstPassengerCard)
      setAltDiscountOpened(isOpened)
    }
  }, [firstPassengerCard, opened, seats])

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={t('seats.title')}
      header={header}
      fullScreen={isMobile}
      className={bem('seat-selection', 'modal')}
      footer={
        <Footer
          requiredSeats={requiredSeats}
          reservedSeatsCount={reservedSeatsCount}
          passengerCount={selected.length}
          outboundSeatsCount={outboundSeatsCount}
          onClick={submit}
          price={sumPrice}
        />
      }
    >
      <>
        <div className="seat-selection__tabs column row-lg space-between">
          <div className={bem('seat-selection', 'tabs-fares')}>
            <Tabs options={faresTabs} value={fareClassCode} onClick={handleChangeFareClass} />
          </div>
          {deckTabs.length > 1 && (
            <div className={bem('seat-selection', 'tabs-level')}>
              <Tabs options={deckTabs} value={deckLevel} variant="outlined" onClick={handleChangeDeckLevel} />
            </div>
          )}
        </div>
        <Deck
          seats={filteredSeats}
          priceCategories={priceCategories}
          discountCategories={discountCategories}
          onClick={onSelect}
          selected={selected}
          amenities={amenities}
          passengerDiscountEnabled={passengerDiscountEnabled}
          passengerCard={passengerCard}
          discounts={discounts.data}
          setPassengerCard={setPassengerCard}
        />
      </>
      <AlternativeDiscountModal
        opened={altDiscountOpened}
        selectedDiscount={passengerCard}
        setSelectedDiscount={setPassengerCard}
        onClose={() => setAltDiscountOpened(false)}
        onCloseTrip={onClose}
        discounts={discounts.data}
      />
    </Modal>
  )
}

export default SeatSelection
