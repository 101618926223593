import React, { useMemo } from 'react'

import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import utils from '@lib/utils'
import { Button, Modal } from '@ui'

interface Props {
  opened: boolean
  selectedDiscount: string | null
  setSelectedDiscount: (discount: string | null) => void
  onClose: () => void
  onCloseTrip: () => void
  discounts?: DiscountCard.Item[] | null
}

const AlternativeDiscountModal = (props: Props) => {
  const { opened, selectedDiscount, setSelectedDiscount, onCloseTrip, onClose, discounts } = props
  const { t } = useTranslation()
  const alternativeDiscount = useMemo(() => {
    if (selectedDiscount === 'ANTT_Senior_100' && discounts)
      return utils.array.findBy(discounts, 'name', 'ANTT_Senior_50')

    return null
  }, [discounts, selectedDiscount])

  const onModalClose = () => {
    onClose()
    onCloseTrip()
  }

  const onAlternativeDiscountSelected = () => {
    setSelectedDiscount(alternativeDiscount?.name ?? null)
    onClose()
  }

  return (
    <Modal
      opened={opened}
      onClose={onModalClose}
      maxWidth="md"
      title={t('seats.limitedModal.title')}
      className={bem('limited-seats-taken', 'modal')}
      footer={
        <div className="p-4 row gap-3 center">
          <div className="cell shrink no-grow basis-auto">
            <Button onClick={onAlternativeDiscountSelected}>
              {t(`seats.limitedModal.alternative.${alternativeDiscount ? 'specific' : 'all'}`, {
                discount: alternativeDiscount?.marketingName,
              })}
            </Button>
          </div>
          <div className="cell shrink no-grow basis-auto">
            <Button onClick={onModalClose} variant="outline">
              {t('seats.limitedModal.cancel')}
            </Button>
          </div>
        </div>
      }
    >
      <div className="p-4 text-center">{t('seats.limitedModal.description')}</div>
    </Modal>
  )
}

export default AlternativeDiscountModal
